<template>
  <div class="home-wrap">
    <div class="content-main">
      <div class="product-name">
        {{ productName }}-{{ loginPhone }}
        <span @click="logout" class="logout">退出</span>
      </div>
      <div class="quota-block block">
        <p>最高额度（元）</p>
        <div class="highest-amt-num">{{ loanMaxAmount | amount }}</div>
        <div v-if="!specialProduct" class="amt-input">
          <van-field
            v-model="form.applyAmount"
            type="number"
            placeholder="请输入金额（元）"
          />
        </div>
        <div class="content">
          <van-field
            v-model="texts.supportPeriod"
            label="申请期数"
            placeholder="请选择"
            :rules="required"
            @click="openPeriod()"
            required
            is-link
            input-align="right"
          />
          <van-field
            v-model.trim="form.customerName"
            label="姓名"
            placeholder="上传识别"
            :rules="required"
            required
            disabled
            input-align="right"
          >
            <template #right-icon>
              <ImgIcon
                :size="28"
                color="#4191f9"
                @click="
                  () => {
                    ocrShow = true;
                  }
                "
              />
            </template>
          </van-field>
          <van-field
            v-model.trim="form.certNo"
            label="身份证号"
            placeholder="上传识别"
            :rules="required"
            required
            disabled
            input-align="right"
          />
          <van-field
            v-model.trim="form.bankNo"
            label="银行卡"
            placeholder="输入或上传识别"
            :rules="required"
            required
            clearable
            input-align="right"
          >
            <template #right-icon>
              <ImgIcon :size="28" color="#4191f9" @click="bankOcr" />
            </template>
          </van-field>
          <van-field
            v-model.trim="form.bankPhone"
            label="银行预留手机号"
            placeholder="请输入"
            :rules="required"
            required
            label-width="7.2em"
            input-align="right"
          />
          <!-- <div class="input-wrap verify-code">
            <van-field
              label="验证码"
              v-model="form.smsCode"
              placeholder="请输入验证码"
              required
              type="digit"
              :rules="required"
            />

            <div
              :class="['send-btn', sending ? 'sending' : '']"
              @click="getCode"
            >
              {{ sending ? `${second}s` : "获取验证码" }}
            </div>
          </div> -->
          <van-field
            v-if="!hideLicense"
            v-model.trim="form.carPlateNum"
            label="车牌"
            placeholder="上传识别"
            :rules="required"
            required
            input-align="right"
          >
            <template #right-icon>
              <ImgIcon :size="28" color="#4191f9" @click="licenseOcr" />
            </template>
          </van-field>
          <van-field
            v-if="!hideLicense"
            v-model.trim="form.vin"
            label="车架号"
            placeholder="上传识别"
            :rules="required"
            required
            input-align="right"
          />
          <van-field
            v-if="!hideLicense"
            v-model.trim="form.registerDate"
            label="车辆登记日期"
            placeholder="上传识别"
            :rules="required"
            required
            is-link
            input-align="right"
            @click="datePickerView = true"
          />
          <van-field
            v-if="!hideLicense"
            v-model.trim="form.mileage"
            label="行驶里程(公里)"
            :maxlength="7"
            label-width="7.2em"
            placeholder="请输入"
            :rules="required"
            required
            input-align="right"
          />
          <van-field
            v-model="texts.licensearea"
            label="上牌地区"
            placeholder="请选择"
            :rules="required"
            @click="regionPickerView = true"
            readonly
            required
            is-link
            input-align="right"
          />
          <!-- <van-field
            v-if="!hideLicense"
            v-model.trim="form.address"
            label="上牌地址"
            type="textarea"
            label-width="7.2em"
            placeholder="请输入"
            :rules="required"
            required
            input-align="right"
          /> -->
        </div>
      </div>
    </div>

    <div class="btns">
      <van-button type="info" size="small" @click="saveFn">提交</van-button>
    </div>
    <!-- 选择弹层 -->
    <PopupPicker
      v-model="pickerView"
      :columns="pickerColumns"
      @confirm="pickerConfirm"
    />
    <IdentityOCRv2
      v-model="ocrShow"
      :preOrderNo="preOrderNo"
      @success="ocrSuccess"
    />
    <PopupDatePicker
      v-model="datePickerView"
      @confirm="datePickerConfirm"
      :config="dateConfig"
    />

    <PopupRegionPicker
      v-model="regionPickerView"
      :columnsNum="2"
      @confirm="regionPickerConfirm"
    />
  </div>
</template>
<script>
const required = [
  {
    required: true
  }
];
import { uploadFile } from "@/api/upload";
import { areaList } from "@vant/area-data";
import { Notify } from "vant";
import {
  productInfos,
  ocrBackcard,
  ocrLicense,
  applyCreate,
  authSmsCode,
  orderDetailLatestv2,
  localtionStatus
} from "@/api";
import { getDetailLocation } from "@/api/location.js";
import { celarExpire, clearCache } from "@/utils/app";
import { Dialog } from "vant";
import { LOGIN_EXPIRED } from "@/utils/token";
const naturalNumber = (v, name) => {
  const reg = /^(?:0|(?:-?[1-9]\d*))$/;
  if (!v) throw `请输入${name}`;
  else if (v && +v < 0) throw `${name}大于等于零的数字`;
  else if (v && !reg.test(v)) throw `${name}请输入整数`;
  else return true;
};
export default {
  components: {
    ImgIcon: () => import("./components/ImgIcon.vue"),
    PopupDatePicker: () => import("./components/PopupDatePicker.vue"),
    PopupRegionPicker: () => import("./components/PopupRegionPicker.vue"),
    PopupPicker: () => import("./components/PopupPicker.vue"),
    IdentityOCRv2: () => import("./IdentityOCRv2.vue")
  },
  data() {
    return {
      cacheData: {},
      useLoaction: true,
      locationStatus: false,
      loanMaxAmount: 0, // 最高金额
      productName: "产品名称",
      loginPhone: "",
      areaInfo: {},
      texts: {}, // 选择field 绑定的文本值
      form: {}, // 选择field 真实key值
      pageLoading: false,
      supportPeriod: [],
      datePickerView: false,
      preOrderNo: "", // 生成的订单号
      required,
      allenums: {}, // 所有枚举
      pickerColumns: [], // 弹出层 选项
      pickerView: false,
      sending: false, // loading
      second: 60,
      regionPickerView: false,
      ocrShow: false,
      itv: null, // 定时器
      upperCaseKeys: []
    };
  },
  mounted() {
    const data = localStorage.getItem("_app_cache_data_") || "{}";
    this.cacheData = JSON.parse(data);
    this.preOrderNo = this.cacheData.preOrderNo || "";
    this.loginPhone = this.cacheData.phone || "";
    this.getProductInfos();
    this.localtionStatus();

    this.ipLocation();
    window.testFollow = () => {
      const { bizNo } = this.cacheData;
      this.$eventbus.$emit("verifyFollowTarget", {
        bizNo,
        callback: (res) => {
          if (res && res == "VERIFIED") {
            // this.getPdfUrl();
            this.$router.push("/credit-extension");
          }
        }
      });
    };
  },
  computed: {
    dateConfig() {
      let currentDate = new Date(this.form.registerDate);
      return {
        minDate: new Date("1970-01-01"),
        currentDate
      };
    },
    // fields() {},
    // ams25:申请页对指定的产品处理：隐藏掉申请金额、还款方式   前端可默认传：申请金额：0
    specialProduct() {
      const nos = this.getNos2();
      const { productNo } = this.cacheData;
      return nos?.includes(productNo);
    },
    hideLicense() {
      const nos = this.getNos();
      const { productNo } = this.cacheData;
      return nos.includes(productNo);
    },
    requiredKeys() {
      let pre = {
        // applyAmount: "申请金额",
        supportPeriod: "申请期数",
        customerName: "姓名",
        certNo: "身份证号",
        bankNo: "银行卡",
        bankPhone: "银行预留手机号"
        // smsCode: "验证码"
      };
      if (!this.specialProduct) pre.applyAmount = "申请金额";
      let suff = {
        carPlateNum: "车牌",
        vin: "车架号",
        registerDate: "车辆登记日期",
        mileage: "行驶里程",
        licenseCode: "上牌地区"
        // address: "上牌地址"
      };
      if (this.hideLicense) {
        return pre;
      } else {
        return {
          ...pre,
          ...suff
        };
      }
    },
    checkedField() {
      const pre = {
        certNo: (v) => {
          const reg =
            /^\d{6}((((((19|20)\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|(((19|20)\d{2})(0[13578]|1[02])31)|((19|20)\d{2})02(0[1-9]|1\d|2[0-8])|((((19|20)([13579][26]|[2468][048]|0[48]))|(2000))0229))\d{3})|((((\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|((\d{2})(0[13578]|1[02])31)|((\d{2})02(0[1-9]|1\d|2[0-8]))|(([13579][26]|[2468][048]|0[048])0229))\d{2}))(\d|X|x)$/;
          if (v && !reg.test(v)) throw "请输入正确的身份证号";
          else return true;
        },
        customerName: (v) => {
          const reg = /^(?:[\u4e00-\u9fa5·]{2,20})$/;
          if (v && !reg.test(v)) throw "请输入正确的姓名";
          else return true;
        },
        bankPhone: (v) => {
          const reg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
          if (v && !reg.test(v)) throw "请输入正确的手机号";
          else return true;
        }
      };
      const suff = {
        vin: (v) => {
          const reg = /^[A-HJ-NPR-Z\d]{8}[X\d][A-HJ-NPR-Z\d]{3}\d{5}$/;
          if (v && !reg.test(v)) throw "请输入正确的车架号";
          else return true;
        },
        carPlateNum: (v) => {
          const reg =
            /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-HJ-NP-Z][A-HJ-NP-Z0-9]{4,5}[A-HJ-NP-Z0-9挂学警港澳]$/;
          if (v && !reg.test(v)) throw "请输入正确的车牌号";
          else return true;
        },
        mileage: (v) => {
          return naturalNumber(v, "行驶里程");
        }
      };
      if (this.hideLicense) {
        return pre;
      } else {
        return {
          ...pre,
          ...suff
        };
      }
    }
  },
  watch: {
    "form.applyAmount": async function (v) {
      try {
        await this.applyAmountCheck(v);
      } catch (error) {
        this.$errMsg(error);
      }
    },
    ocrShow(v) {
      if (v) {
        window.addEventListener("popstate", this.backevent);
      } else {
        window.removeEventListener("popstate", this.backevent);
      }
    },
    useLoaction(v) {
      if (!v) Notify({ type: "warning", message: "暂未开启定位", duration: 0 });
    }
  },
  methods: {
    getNos() {
      const isdev = localStorage.getItem("dev_key");
      if (isdev)
        // 测试用
        return localStorage.getItem("productNos").split(",");
      else return ["1018918947929485312"];
    },
    getNos2() {
      const isdev = localStorage.getItem("dev_key");
      if (isdev)
        // 测试用
        return localStorage.getItem("productNos2")?.split(",");
      else
        return [
          "917830528319623168", // 黑户贷-测试/917830528319623168
          "1018918947929485312" // 生产产品 -吴凡
        ];
    },
    // 判断是否打开授权弹窗
    async judgeShowAuthDlg() {
      try {
        const { productNo, merchantNo } = this.cacheData;
        await this.refreshCacheData(productNo, merchantNo);
        const { bizNo, hasAuth } = this.cacheData;
        if (hasAuth == "Y") {
          this.$router.replace(`/index?bizNo=${bizNo}`);
          return;
        }
        this.bizNo = bizNo || "";
        if (this.bizNo)
          this.$eventbus.$emit("verifyFollowTarget", {
            bizNo,
            callback: (res) => {
              if (res && res == "VERIFIED") {
                // this.getPdfUrl();
                this.$router.push("/credit-extension");
              }
            }
          });
      } catch (error) {
        this.$errMsg(error);
      }
    },
    datePickerConfirm(date) {
      this.$set(this.form, "registerDate", date);
    },
    // 区域选择确认
    regionPickerConfirm(codes, names) {
      const [provinceCode, cityCode] = codes;
      const [provinceName, cityName] = names;
      this.texts.licensearea = `${provinceName}/${cityName}`;
      this.form.licenseCode = cityCode;
    },
    backevent() {
      history.pushState(null, null, location.href);
    },
    ocrSuccess() {
      const jsonData = localStorage.getItem("_app_cache_data_") || "{}";
      let data = JSON.parse(jsonData);
      const { customerName, certNo } = data;
      this.$set(this.form, "customerName", customerName);
      this.$set(this.form, "certNo", certNo);
    },
    async createdInput() {
      const input = document.createElement("input");
      input.type = "file";
      input.accept = "image/*";
      input.click();
      return new Promise((resp) => {
        input.onchange = async () => {
          const file = input.files[0];
          this.$toast.loading({
            message: "图片上传识别中……",
            duration: 0, // 持续展示 toast
            forbidClick: true
          });
          const { ossAccessUrl } = await uploadFile(file);
          return resp(ossAccessUrl);
        };
      });
    },
    async bankOcr() {
      if (this.form.bankNo) {
        this.$toast("清空银行卡号后重新识别");
        return;
      }
      try {
        const ossAccessUrl = await this.createdInput();
        const preOrderNo = this.preOrderNo;
        const { productNo, merchantNo } = this.cacheData;
        const { data } = await ocrBackcard({
          preOrderNo,
          imageUrl: ossAccessUrl,
          productNo,
          merchantNo,
          cardType: true
        });
        let form = {
          ...this.form
        };
        form.bankNo = data.cardNum || "";
        // form.bankPhone = data.bankPhone || "";
        this.$set(this, "form", form);
        this.$toast.clear();
      } catch (error) {
        this.$errMsg(error);
      }
    },
    async licenseOcr() {
      try {
        const ossAccessUrl = await this.createdInput();
        const preOrderNo = this.preOrderNo;
        const { productNo, merchantNo } = this.cacheData;
        const { data } = await ocrLicense({
          preOrderNo,
          imageUrl: ossAccessUrl,
          productNo,
          merchantNo,
          side: "face"
        });
        let form = {
          ...this.form
        };
        form.carPlateNum = data.carPlateNum || "";
        form.vin = data.vin || "";
        form.registerDate = data.registerDate || "";
        form.address = data.address || "";
        this.$set(this, "form", form);
        this.$toast.clear();
      } catch (error) {
        this.$errMsg(error);
      }
    },
    async saveFn() {
      if (this.pageLoading) return;
      try {
        console.log(this.areaInfo);
        const { longitude, latitude } = this.areaInfo || {};
        if (this.locationStatus && (!longitude || !latitude)) {
          throw "该产品必须允许位置权限。请清理缓存后重新登录授权";
        }
        if (!this.specialProduct)
          await this.applyAmountCheck(this.form.applyAmount);
        for (let key in this.requiredKeys) {
          if (!this.form[key]) throw `${this.requiredKeys[key]}字段必填`;
        }
        for (let key in this.checkedField) {
          const val = this.form[key];
          const fn = this.checkedField[key];
          fn(val);
        }
        const {
          phone,
          productNo,
          merchantNo,
          agentNo,
          customerId,
          repaymentMethod
        } = this.cacheData;
        const params = {
          preOrderNo: this.preOrderNo,
          // 申请页对指定的产品处理：隐藏掉申请金额、还款方式   前端可默认传：申请金额：0
          applyAmount: this.specialProduct ? 0 : this.form.applyAmount,
          customerPhone: phone,
          applyProductNo: productNo,
          // 没心情做映射 就这样
          customerName: this.cacheData.customer_name,
          certNo: this.cacheData.cert_no,
          merchantNo,
          agentNo,
          applyTerm: this.form.supportPeriod,
          customerId,
          repaymentMethod,
          ...this.form,
          // ...this.fieldsForm,
          // fieldInfoList,
          ...this.areaInfo
        };
        this.$toast.loading({
          message: "处理中...",
          forbidClick: true
        });
        this.pageLoading = true;
        await applyCreate(params);
        this.$toast("申请成功");
        await this.refreshCacheData();
        const { bizNo } = this.cacheData;
        this.$eventbus.$emit("verifyFollowTarget", {
          bizNo,
          callback: (res) => {
            if (res && res == "VERIFIED") {
              // this.getPdfUrl();
              this.$router.push("/credit-extension");
            }
          }
        });
      } catch (error) {
        this.$toast.clear();
        this.$errMsg(error);
      } finally {
        this.pageLoading = false;
      }
    },
    // 刷新cacheData缓存
    async refreshCacheData() {
      const { productNo, merchantNo } = this.cacheData;
      const orderDetailResp = await orderDetailLatestv2(productNo, merchantNo);

      if (orderDetailResp && orderDetailResp.data) {
        const {
          bizNo,
          hasAuth,
          customerName,
          certNo,
          hasIdentification,
          isIdentification
        } = orderDetailResp.data;

        let jsonData = {
          ...this.cacheData,
          bizNo,
          hasAuth,
          customerName,
          certNo,
          hasIdentification, // 是否已经四要素认证
          isIdentification // 是否需要四要素认证
        };
        this.cacheData = jsonData;
        await localStorage.setItem(
          "_app_cache_data_",
          JSON.stringify(jsonData)
        );
      }
    },
    // 金额校验
    applyAmountCheck(val) {
      const reg = /^\+?[1-9]\d*$/;
      if (!reg.test(val) || +val > +this.loanMaxAmount) {
        return Promise.reject("请输入正整数，且小于等于最高额度");
      } else {
        return Promise.resolve(true);
      }
    },
    async getCode() {
      if (this.sending) return;
      const mobilePass = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(
        this.form.bankPhone
      );
      if (!mobilePass) {
        this.$toast.fail("请输入正确的手机号");
        return;
      }
      try {
        this.$toast.loading({
          message: "处理中...",
          forbidClick: true
        });
        await authSmsCode(this.form.bankPhone);

        setTimeout(() => {
          this.$toast.success("验证码已发送，请注意查收！");
        }, 100);
        this.sending = true;
        this.itv = setInterval(() => {
          this.second--;
          if (this.second <= 0) {
            this.second = 60;
            this.sending = false;
            clearInterval(this.itv);
          }
        }, 1000);
        return Promise.resolve(true);
      } catch (error) {
        this.sending = false;

        this.$errMsg(error);
        return Promise.reject(error);
      }
    },
    // 打开
    openPeriod() {
      this.pickerColumns = this.allenums.supportPeriod;
      this.pickerView = true;
    },
    // 弹出层确认
    pickerConfirm({ value, text }) {
      this.$set(this.texts, "supportPeriod", text);
      this.$set(this.form, "supportPeriod", value);
    },
    upperCase(key) {
      this.form[key] = this.form[key].toLocaleUpperCase();
    },
    // 根据 城市信息反查 区域code
    async ipLocation() {
      try {
        const that = this;
        /* eslint-disable-next-line */
        var lo = new T.Geolocation();

        const fn = function (e) {
          console.log("查看地图", e.lnglat);

          const lat = e?.lnglat?.lat;
          const lng = e?.lnglat?.lng;
          if (lat && lng) {
            that.getDetailLocation(lng, lat);
          } else {
            this.useLoaction = false;
            console.log("获取定位失败", e);
          }
        };
        lo.getCurrentPosition(fn);
      } catch (error) {
        this.useLoaction = false;
        this.$errMsg(error);
      }
    },
    async localtionStatus() {
      try {
        const { productNo } = this.cacheData;
        const { data } = await localtionStatus(productNo);
        this.locationStatus = data?.locationStatus == "Y";
        if (this.locationStatus) {
          Dialog.confirm({
            title: "提示",
            message: "该产品必须允许位置权限，无授权不能申请。"
          });
        }
      } catch (error) {
        this.$errMsg(error);
      }
    },
    // 通过城市code获取城市name
    getAraeNameByCode(p, c) {
      const { province_list, city_list } = areaList;

      let c1 = province_list[p];
      let c2 = city_list[c];

      return [c1, c2];
    },
    async getDetailLocation(lng, lat) {
      try {
        const resp = await getDetailLocation(lng, lat);
        let data = resp?.data?.result?.addressComponent ?? {};
        // let data = testData?.result?.addressComponent ?? {};
        let applyAddress = resp?.data?.result?.formatted_address;
        let cityCode =
          data.city_code && typeof data.city_code == "string"
            ? data.city_code.slice(3)
            : "";
        let provinceCode =
          data.province_code && typeof data.province_code == "string"
            ? data.province_code.slice(3)
            : "";

        if (!cityCode)
          cityCode =
            data.county_code && typeof data.county_code == "string"
              ? data.county_code.slice(3).slice(0, 4) + "00"
              : "";

        const [proName, cityName] = this.getAraeNameByCode(
          provinceCode,
          cityCode
        );
        this.areaInfo = {
          applyAddress,
          longitude: lng,
          latitude: lat,
          cityCode: cityCode,
          cityName: cityName,
          provinceName: proName,
          provinceCode: provinceCode
        };
        this.lastcode = cityCode;
        this.texts.area = `${proName}/${cityName}`;
      } catch (e) {
        console.log("e", e);
        this.$errMsg(e);
      }
    },
    // 产品信息
    async getProductInfos() {
      try {
        this.pageLoading = true;
        const { productNo } = this.cacheData;
        const { data } = await productInfos(productNo);
        const {
          repaymentMethodStr,
          supportPeriods,
          repaymentMethod,
          loanMaxAmount,
          productName
        } = data || {};
        this.loanMaxAmount = loanMaxAmount || 0;
        this.productName = productName || "产品名称";
        this.cacheData = {
          ...this.cacheData,
          repaymentMethod,
          repaymentMethodStr
        };
        const enums = (supportPeriods || []).map((value) => ({
          text: `${value} 期`,
          value
        }));
        // .filter((e, i) => !i); // 测试仅有一项
        this.$set(this.allenums, "supportPeriod", enums);
        if (enums.length && enums.length == 1) {
          this.$set(this.form, "supportPeriod", enums[0].value);
          this.$set(this.texts, "supportPeriod", enums[0].text);
        }
        this.texts.repaymentMethodStr = repaymentMethodStr;
      } catch (error) {
        this.$errMsg(error);
      } finally {
        this.pageLoading = false;
      }
    },
    logout() {
      Dialog.confirm({
        title: "提示",
        message: "确定要退出当前用户吗？"
      })
        .then(() => {
          const str = localStorage.getItem("_app_cache_data_") || "{}";

          const data = JSON.parse(str);
          const { productNo, merchantNo } = data;

          celarExpire();
          clearCache();

          localStorage.setItem("_app_cache_data_", "");
          localStorage.setItem(LOGIN_EXPIRED, 0);
          this.$router.push(
            `/login?productNo=${productNo}&merchantNo=${merchantNo}`
          );
        })
        .catch(() => {
          // on cancel
        });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
.home-wrap {
  .content-main {
    .product-name {
      margin-top: 20px;
    }

    .logout {
      font-size: 24px;
      background-color: #f7dfe2aa;
      border: 1px solid #f8808e;
      color: #ee0a24;
      padding: 0 12px;
      margin-left: 16px;
      border-radius: 8px;
    }
    .quota-block {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-top: 16px;
      padding: 48px 0;
      .highest-amt-num {
        font-size: 40px;
        height: 40px;
        line-height: 40px;
      }
      .amt-input {
        margin: 16px auto;
      }
    }
  }
  .head {
    height: 96px;
    text-align: center;
    line-height: 96px;
    font-size: 32px;
    font-weight: 600;
    color: #333;
    border-bottom: 1px solid #efefef;
  }
  :deep(.van-field__right-icon) {
    display: flex;
    align-items: center;
  }
  .content {
    flex: 1;
    overflow-y: auto;
    .van-cell {
      padding: 10px 32px;
    }
    .input-wrap {
      width: 100%;
      height: 96px;
      display: flex;
      align-items: center;
      overflow: hidden;
      :deep(.van-field__label) {
        width: 7em;
      }
      &.verify-code {
        display: flex;
        justify-content: space-between;
        .send-btn {
          box-sizing: border-box;

          height: 28px;
          line-height: 28px;
          border-left: 2px solid #1890ff;
          font-size: 28px;
          font-weight: 400;
          color: #1890ff;
          text-align: center;
          padding-left: 6px;

          white-space: nowrap;

          padding: 0px 32px;
          &.sending {
            color: #999;
          }
        }
      }
    }
  }
  .btns {
    height: 112px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .van-button {
      width: 90%;
      height: 75%;
    }
  }
}
</style>
